import React from 'react';

import { Grid, Chip, Stack } from '@mui/material';

import resistanceGenesReference from './resistanceGenesReference.js'

const CALL_COLORS = {
  default: '#0081ff',
  resistant: '#ff9800',
  susceptible: 'green',
  resistanceGene: 'grey'
};

const styles = {
  container: {
    pl: 3,
    pr: 1,
    textAlign: 'center',
    borderBottom: '#bdbdbd 2px solid',
    borderLeft: '#bdbdbd 2px solid'
  },
  drugName: {
    textAlign: 'left'
  },
  smallText: {
    fontWeight: 'normal'
  },
  confidenceText: {
    color: 'grey'
  },
  imputedText: {
    color: 'grey'
  },
  resistanceGenes: {
    justifyContent: 'center',
    alignItems: 'center'
  }
};

function predictionIsSusceptible(predictionAttributes) {
  if (!predictionAttributes) {
    return false;
  }

  return predictionAttributes.amr_call === 'S' || predictionAttributes.call === 'S';
}

function predictionIsResistant(predictionAttributes) {
  if (!predictionAttributes) {
    return false;
  }

  return predictionAttributes.amr_call === 'R' || predictionAttributes.call === 'R';
}

function isHighPerformingModel(predictionAttributes) {
  if (!predictionAttributes) {
    return false;
  }

  return ['H', 'VH'].includes(predictionAttributes.model_performance_label);
}

function isKgASTPrediction(predictionAttributes) {
  if (!predictionAttributes) {
    return false;
  }

  return !!predictionAttributes.model_performance_label;
}

/**
 * Utilizes a reference of all predictions for a sample to determine the prediction
 * and associated antibiotic that an imputation was derived from.
 */
function imputedFromAntibiotic(allPredictionsReference, imputationAttributes) {
  if (!imputationAttributes) {
    return false;
  }

  return allPredictionsReference[imputationAttributes.amr_model_prediction_id].attributes.model_drug
}

function resistanceGeneIsRelevantForAntibiotic(resistanceGene, antibiotic) {
  if(!resistanceGenesReference[resistanceGene]) {
    return false;
  }

  return resistanceGenesReference[resistanceGene].antibiotics.includes(antibiotic);
}

function AMRPredictionRow(props) {
  const {
    prediction,
    amrPredictions,
    isDiagnosticView,
    showResistanceGenes,
    resistanceGenes
  } = props;

  let callColor = CALL_COLORS.default;
  let callText = 'Intermediate';
  
  if (predictionIsResistant(prediction.attributes)) {
    callColor = CALL_COLORS.resistant;
    callText = 'Resistant';
  } else if (predictionIsSusceptible(prediction.attributes)) {
    callColor = CALL_COLORS.susceptible;
    callText = 'Susceptible';
  } else if (prediction.resistanceGenes) {
    callColor = CALL_COLORS.resistanceGene;
    callText = (
      <small style={styles.smallText}>
        Resistance Gene{prediction.resistanceGenes.length === 1 ? '' : 's'} Found
      </small>
    )
  }

  const predictionAttributes = prediction.attributes;

  let confidenceContent = null;
  if (!isDiagnosticView && isKgASTPrediction(predictionAttributes)) {
    const confidenceText = isHighPerformingModel(predictionAttributes) ? 'Qualified Model' : 'R&D Model';
    confidenceContent = (
      <Grid item xs={12} sm={3}>
        <p>
          <b><span style={styles.confidenceText}>{confidenceText}</span></b>
        </p>
      </Grid>
    );
  }

  let imputedFromContent = null;
  if (!isDiagnosticView && !isKgASTPrediction(predictionAttributes) && !prediction.resistanceGenes) {
    imputedFromContent = (
      <Grid item xs={12} sm={3}>
        <p>
          <span style={styles.imputedText}>
            <small>Imputed from: <b>{imputedFromAntibiotic(amrPredictions, predictionAttributes)}</b> prediction</small>
          </span>
        </p>
      </Grid>
    );
  }

  let resistanceGeneContent = null;
  if (!isDiagnosticView && resistanceGenes && showResistanceGenes && !prediction.resistanceGenes) {
    resistanceGeneContent = (
      <Stack direction="row" spacing={1} sx={styles.resistanceGenes}>
        {
          [...resistanceGenes]
            .filter(resistanceGene => resistanceGeneIsRelevantForAntibiotic(resistanceGene, predictionAttributes.model_drug || predictionAttributes.drug))
            .map(item => (
              <Chip size="small" label={resistanceGenesReference[item].display_name} color="primary" />
            ))
        }
      </Stack>
    );
  }

  if (!isDiagnosticView && prediction.resistanceGenes && showResistanceGenes) {
    resistanceGeneContent = (
      <Stack direction="row" spacing={1} sx={styles.resistanceGenes}>
        {
          prediction.resistanceGenes
            .map(item => (
              <Chip size="small" label={item} color="primary" />
            ))
        }
      </Stack>
    );
  }

  return (
    <Grid container sx={styles.container}>
      <Grid item xs={12} sm={isDiagnosticView ? 10 : 3} sx={styles.drugName}>
        <p><b>{prediction.attributes?.model_drug || prediction.attributes?.drug || prediction.drug}</b></p>
      </Grid>
      {prediction.resistanceGenes ? <Grid item xs={12} sm={2}></Grid> : null}
      <Grid item xs={12} sm={prediction.resistanceGenes ? 3 : 2}>
        <b><p style={{ color: callColor }}>{callText}</p></b>
      </Grid>
      {confidenceContent}
      {imputedFromContent}
      {resistanceGeneContent}
    </Grid>
  );
}

export default AMRPredictionRow;
