function removePrefixFromAnalysisVersion(analysisVersion) {
    if (!analysisVersion) {
        return analysisVersion;
    }

    return analysisVersion.replace(/^(ont|illumina)-/, '')
}

export {
    removePrefixFromAnalysisVersion
}