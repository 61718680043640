import React, {useMemo, useState, useCallback, useEffect} from 'react';

import {useDropzone} from 'react-dropzone'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { Menu, MenuItem, IconButton, DialogContentText } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { datadogRum } from '@datadog/browser-rum';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';


import AddSamplesFileListItem from './AddSamplesFileListItem';
import SamplesList from './SamplesList';

import ontLogo from '../ont_logo.svg';
import illuminaLogo from '../illumina_logo.svg';

function isIlluminaDataset(activeRun) {
    if (!activeRun) {
        return false;
    }

    return activeRun.attributes.sequencing_platform === 'illumina'
        || activeRun.attributes.platform === 'illumina';
}

function validateR1R2Suffix(file) {
    if (
        file.name.endsWith('_1.fastq.gz')
        || file.name.endsWith('_2.fastq.gz')
        || file.name.endsWith('_R1.fastq.gz')
        || file.name.endsWith('_R2.fastq.gz')
    ) {
        return null
    }

    return {
        "message": "File names must contain _1, _2, _R1, or _R2 suffix"
    }
}

function extractFileBasename(filename) {
    return filename.replace('.fastq.gz', '').replace('./', '')
}

function basenameIsR1File(basename) {  
    return basename.endsWith('1') || basename.endsWith('R1')
}

function extractSampleNameFromFilename(filename, isIllumina) {
    const basename = extractFileBasename(filename)
    if (!isIllumina) {
        return basename
    }

    if (basenameIsR1File(basename)) {
        return basename.replace(/_(1|R1)$/, '')
    }

    return basename.replace(/_(2|R2)$/, '')
}

function AddSamplesDialog(props) {
    const { 
        open, 
        setOpen, 
        activeRun, 
        reloadSamples, 
        uploadFile, 
        backgroundUploadingFilesCt, 
        samples, 
        activeProject, 
        setActiveRun,
        setReloadRuns,
        visibleRuns,
        setVisibleRuns,
        setUserErrorMessage
    } = props;
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
    const [isArchiving, setIsArchiving] = useState(false);
    const navigate = useNavigate();
    const [ stagedFiles, setStagedFiles] = useState({});
    const [ uploadingCounter, setUploadingCounter ] = useState(0);
    const { getAccessTokenSilently } = useAuth0();
    const [archiveProject, setArchiveProject] = useState(null);


    useEffect(() => setStagedFiles({}), [open]);

    const handleMenuClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
      };
      
    const handleMenuClose = (event) => {
        event?.preventDefault();
        event?.stopPropagation();
        setMenuAnchorEl(null);
    };
    
    const handleArchiveClick = () => {
        setArchiveDialogOpen(true);
        handleMenuClose();
    };

    const handleArchiveCancel = () => {
        setArchiveDialogOpen(false);
    };

    const handleClose = useCallback((reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        setOpen(false);
    }, [setOpen]);

    const fetchArchiveProject = async (headers, organizationId) => {
        if (!organizationId) {
            return null;
        }
        const res = await fetch(
          `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/organizations/${organizationId}/projects`,
          { method: 'GET', headers }
        );
        
        if (!res.ok) {
          throw new Error(`Request to ${res.url} failed with ${res.status} (${res.statusText})`) 
        }
        
        const data = await res.json();
        const archiveProject = data.data.find(project => project?.attributes?.name === "archive");
        return archiveProject;
    };

    const moveDatasetToProject = useCallback(async (headers, datasetID, projectID) => {
        const res = await fetch(
          `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/sequencing_runs/${datasetID}`,
          {
            method: 'PUT',
            headers: { ...headers, 'Content-Type': 'application/json' },
            body: JSON.stringify({
              data: {
                type: "sequencing_run",
                id: datasetID,
                attributes: { project_id: projectID }
              }
            })
          }
        );
      
        if (!res.ok) {
          setUserErrorMessage('Failed to archive dataset. Please wait a few minutes and try again. Contact support@dayzerodiagnostics.com if you require assistance.');
          throw new Error(`Request to ${res.url} failed with ${res.status} (${res.statusText})`);
        }
        return res.json();
      }, [setUserErrorMessage]);
    
    useEffect(() => {
      const loadArchiveProject = async () => {
          const accessToken = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${accessToken}` };
          const archiveProject = await fetchArchiveProject(headers, activeProject?.attributes.organization_id);
          setArchiveProject(archiveProject)
     } 
     loadArchiveProject();
    }, [activeProject, getAccessTokenSilently]);
      
    
    const handleArchiveConfirm = useCallback(() => {
        const archiveDataset = async () => {
           try {
             setIsArchiving(true);
             const accessToken = await getAccessTokenSilently();
             const headers = { Authorization: `Bearer ${accessToken}` };
             await moveDatasetToProject(headers, activeRun.id, archiveProject.id);
             handleClose()
             setVisibleRuns(visibleRuns.filter(run => run.id !== activeRun.id));
             setActiveRun(null)
             setArchiveDialogOpen(false);
             setReloadRuns()
             navigate('/');
           } catch (error) {
              datadogRum.addError(error);
              alert(`Failed to archive dataset: ${error.message}`);
            } finally {
              setIsArchiving(false);
            }
          }
          archiveDataset();
        }, [activeRun, archiveProject, getAccessTokenSilently, navigate, handleClose, setActiveRun, setReloadRuns, setVisibleRuns, visibleRuns, moveDatasetToProject]);

    const onDrop = useCallback(acceptedFiles => {
        let newStagedFiles = stagedFiles;
        const isIllumina = isIlluminaDataset(activeRun);
        for (let file of acceptedFiles) {
            let sampleName = extractSampleNameFromFilename(file.name, isIllumina)
            if (isIllumina) {
                if (!newStagedFiles[sampleName]) { 
                    newStagedFiles[sampleName] = {
                        'R1': null,
                        'R2': null
                    }
                }

                const fileKey = basenameIsR1File(extractFileBasename(file.name)) ? 'R1' : 'R2'
                newStagedFiles[sampleName][fileKey] = file
            } else {
                newStagedFiles[sampleName] = file
            }
        }

        setStagedFiles(newStagedFiles)
    }, [setStagedFiles, stagedFiles, activeRun])

    const isIllumina = isIlluminaDataset(activeRun);
    const acceptedSuffixes = ['.fastq.gz']

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop, 
        'accept': {'application/gzip': acceptedSuffixes, 'application/x-gzip': acceptedSuffixes},
        validator: isIllumina ? validateR1R2Suffix : null
    })

    const uploadingStarted = useCallback(() => {
        setUploadingCounter(uploadingCounter + 1)
    }, [uploadingCounter, setUploadingCounter])

    const uploadingFinished = useCallback(() => {
        setUploadingCounter(uploadingCounter - 1)
    }, [uploadingCounter, setUploadingCounter])

    const deleteSample = useCallback(sampleNameToDelete => {
        let newStagedFiles = {};
        for (let sampleName in stagedFiles) {
            if (sampleName === sampleNameToDelete) {
                continue;
            }

            newStagedFiles[sampleName] = stagedFiles[sampleName]
        }

        setStagedFiles(newStagedFiles)
    }, [setStagedFiles, stagedFiles])

    const style = useMemo(() => {
        const dropZoneStyle = {
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              padding: '20px',
              borderWidth: 2,
              borderRadius: 2,
              borderColor: '#eeeeee',
              borderStyle: 'dashed',
              backgroundColor: '#fafafa',
              color: '#bdbdbd',
              cursor: "pointer",
              outline: 'none',
              transition: 'border .24s ease-in-out'
        };

        const focusedStyle = {
          borderColor: '#2196f3'
        };

        const acceptStyle = {
          borderColor: '#00e676'
        };

        const rejectStyle = {
          borderColor: '#ff1744'
        };

        return {
            ...dropZoneStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }
    }, [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const existingSampleNames = useMemo(() => {
        if (!samples) {
            return []
        }

        return samples.map(sample => sample.attributes.sample_name)
    }, [samples])

    return (
        <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        >
            <DialogTitle>
                <Grid container>
                    <Grid xs={8}>
                        {activeRun?.attributes.name}
                        {archiveProject ? (
                            <>
                        <IconButton
                            size="small"
                            onClick={handleMenuClick}
                            sx={{ ml: 1 }}
                            >
                            <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={menuAnchorEl}
                                open={Boolean(menuAnchorEl)}
                                onClose={handleMenuClose}
                                onClick={handleMenuClick}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                            <MenuItem onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                handleArchiveClick();
                                handleMenuClose();
                            }}>
                                Archive Dataset
                            </MenuItem>
                            </Menu>
                            </>
                        ) : null}
                    </Grid>
                    <Dialog
                        open={archiveDialogOpen}
                        onClose={handleArchiveCancel}
                    >
                        <DialogTitle>Archive Dataset</DialogTitle>
                        <DialogContent>
                        <DialogContentText>
                            Are you sure you want to archive this dataset? After archiving, you will no longer be able to see any samples or results associated with this dataset.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleArchiveCancel} disabled={isArchiving}>Cancel</Button>
                        <Button 
                            onClick={handleArchiveConfirm}
                            color="primary"
                            variant="contained"
                            autoFocus
                            disabled={isArchiving}
                        >
                            {isArchiving ? 'Archiving...' : 'Yes, Archive'}
                        </Button>
                        </DialogActions>
                    </Dialog>
                    <Grid xs={4} sx={{textAlign: "right"}}>
        { isIllumina ? (
                        <>
                        <img src={illuminaLogo} alt="platform-logo" height="30px" style={{marginBottom: '-8px', marginLeft: '4px', marginRight: '10px'}} />
                        Illumina Paired End
            </>

        ) : (
            <>
                        <img src={ontLogo} alt="platform-logo" height="30px" style={{marginBottom: '-8px', marginLeft: '4px', marginRight: '10px'}} />
                        ONT Single End
            </>
        )}
                    </Grid>
                </Grid>
      <Grid item xs={12}>
        <Box>
            <Grid container>
              <Grid item xs={6}>
                    <p style={{color: '#797979', marginBottom: '20px', fontSize: 15}}>
                        <b>UPLOAD SEQUENCING FILES</b>
                    </p>
              </Grid>
              <Grid item xs={6} sx={{textAlign: "right"}}>
            <p style={{color: '#797979', marginBottom: '20px', fontSize: 15}}>
                Keynome Manager Upload ID: <pre style={{display: "inline", backgroundColor: "#eee", padding: "5px 10px", borderRadius: "5px"}} >{activeRun?.attributes.uuid}</pre>
            </p>
            </Grid>
            </Grid>
            </Box>
            </Grid>
                <div {...getRootProps()} style={style}>
                  <input {...getInputProps()} />
                  <p style={{fontSize: 15}}>
                    <b>Click to open browser or drag/drop sequencing files</b>
                    <br/>
                    Files must be Gzip compressed with {isIllumina ? '_1/R1.fastq.gz or _2/R2.fastq.gz' : '.fastq.gz'} suffix
                    {isIllumina ? 
                        (
                            <>
                            <br/>
                            Paired sequencing files must have the same file name prior to _1/R1 or _2/R2 suffix
                            </>
                        ):
                        null
                    }
                </p>
                </div>
    {Object.keys(stagedFiles).length ? (
    <>
      <Box sx={{ backgroundColor: '#eee', padding: '1px 10px', color: 'black', marginTop: '20px', fontSize: 15 }}>
        <Grid container sx={{ pl: 1, pr: 1, textAlign: 'center' }}>
          <Grid item xs={12} sm={isIllumina ? 3 : 2} sx={{ textAlign: 'left' }}>
            <p style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <b>Sample Name</b>
                <Tooltip 
                    title="Filenames are used to identify uploaded samples - please ensure no PHI/PII is included in provided filenames."
                    arrow
                >
                    <InfoIcon 
                        sx={{ 
                            color: 'rgba(0, 0, 0, 0.4)',
                            fontSize: 20,
                            cursor: 'pointer'
                        }} 
                    />
                </Tooltip>
            </p>
          </Grid>
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            <p>{isIllumina ? 'R1 ' : ''}File Name</p>
          </Grid>
        {
            isIllumina ? 
            (
              <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
                <p>R2 File Name</p>
              </Grid>
            ) : null

        }
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            <p>{isIllumina ? 'Total ' : ''}File Size</p>
          </Grid>
          {isIllumina ? null : (
          <Grid item xs={12} sm={3} sx={{ textAlign: 'center' }}>
            <p>File Last Modified</p>
          </Grid>
          )}
          <Grid item xs={12} sm={3} sx={{ textAlign: 'center' }}>
            &nbsp;
          </Grid>
        </Grid>
      </Box>
      <List sx={{
          width: '100%', bgcolor: 'background.paper', paddingTop: '0', paddingBottom: '0', maxHeight: "200px", overflowY: 'auto'
      }}
      >
        {Object.keys(stagedFiles).map(sampleName => {
            if (!isIllumina) {
                const file = stagedFiles[sampleName]
                return <AddSamplesFileListItem sampleName={sampleName} file={file} r2File={null} deleteSample={deleteSample} activeRun={activeRun} uploadingStarted={uploadingStarted} uploadingFinished={uploadingFinished} uploadFile={uploadFile} reloadSamples={reloadSamples} sampleExists={existingSampleNames.includes(sampleName)} setUserErrorMessage={setUserErrorMessage} />
            }    

            const r1File = stagedFiles[sampleName].R1
            const r2File = stagedFiles[sampleName].R2
            return <AddSamplesFileListItem sampleName={sampleName} file={r1File} r2File={r2File} deleteSample={deleteSample} activeRun={activeRun} uploadingStarted={uploadingStarted} uploadingFinished={uploadingFinished} uploadFile={uploadFile} isIllumina={true} reloadSamples={reloadSamples} sampleExists={existingSampleNames.includes(sampleName)} setUserErrorMessage={setUserErrorMessage} />
        })}
      </List>
                </>
    ) : null}
        <Divider sx={{margin: '40px 40px 0 40px'}} />
            </DialogTitle>
            <DialogContent>
      <Grid item xs={12}>
        <p style={{color: '#797979', marginBottom: '20px'}}>
            <b>SAMPLES IN DATASET</b>
        </p>
        </Grid>
          <SamplesList samples={samples} activeProject={activeProject} selectedRun={activeRun} />
            </DialogContent>
            <DialogActions sx={{marginBottom: "15px"}}>
                {
                    backgroundUploadingFilesCt === 0 ?
                        null :
                        (
                            <p style={{color: "gray", marginRight: "20px", textAlign: "right"}}><small>Files may take a few minutes to upload<br />Feel free to close and upload{backgroundUploadingFilesCt === 1 ? '' : 's'} will continue in the background</small></p>
                        )
                }
                <Button variant="contained" color="primary" sx={{marginRight: "20px"}} onClick={() => {
                    handleClose()
                    setActiveRun(null)
                }} disabled={uploadingCounter !== 0}>Close</Button>
            </DialogActions>
        </Dialog>
    );

}

export default AddSamplesDialog;
