const KgASTv0_7_5_1PanelReference = [
    {
        id: 1,
        organism: "Acinetobacter calcoaceticus/baumannii complex",
        qualified_antibiotics: [
            "amikacin",
            "ciprofloxacin",
            "gentamicin",
            "imipenem",
            "levofloxacin",
            "meropenem"
        ],
        rnd_antibiotics: [
            "ampicillin/sulbactam",
            "cefepime",
            "ceftazidime",
            "colistin",
            "doripenem",
            "minocycline",
            "piperacillin/tazobactam",
            "tetracycline",
            "tobramycin",
            "trimethoprim/sulfamethoxazole"
        ]
    },
    {
        id: 2,
        organism: "Citrobacter freundii complex",
        qualified_antibiotics: [
            "tetracycline"
        ],
        rnd_antibiotics: [
            "trimethoprim/sulfamethoxazole"
        ]
    },
    {
        id: 3,
        organism: "Enterobacter cloacae complex",
        qualified_antibiotics: [
            "gentamicin",
            "tobramycin"
        ],
        rnd_antibiotics: [
            "ciprofloxacin",
            "levofloxacin",
            "trimethoprim/sulfamethoxazole"
        ]
    },
    {
        id: 4,
        organism: "Enterococcus faecalis",
        qualified_antibiotics: [
            "ciprofloxacin",
            "doxycycline",
            "erythromycin",
            "levofloxacin",
            "tetracycline"
        ],
        rnd_antibiotics: [
            "gentamicin high level",
            "minocycline",
            "streptomycin high level",
            "vancomycin"
        ]
    },
    {
        id: 5,
        organism: "Enterococcus faecium",
        qualified_antibiotics: [
            "ampicillin",
            "ciprofloxacin",
            "levofloxacin",
            "tetracycline",
            "vancomycin"
        ],
        rnd_antibiotics: [
            "doxycycline",
            "erythromycin",
            "gentamicin high level"
        ]
    },
    {
        id: 6,
        organism: "Escherichia coli",
        qualified_antibiotics: [
            "ampicillin",
            "aztreonam",
            "cefazolin",
            "cefepime",
            "ceftriaxone",
            "cefuroxime",
            "ciprofloxacin",
            "gentamicin",
            "imipenem",
            "levofloxacin",
            "tetracycline",
            "trimethoprim/sulfamethoxazole"
        ],
        rnd_antibiotics: [
            "amoxicillin",
            "ampicillin/sulbactam",
            "ertapenem",
            "meropenem",
            "piperacillin/tazobactam",
            "tobramycin"
        ]
    },
    {
        id: 7,
        organism: "Klebsiella pneumoniae",
        qualified_antibiotics: [
            "aztreonam",
            "ceftriaxone",
            "ciprofloxacin",
            "gentamicin",
            "imipenem",
            "levofloxacin",
            "tetracycline",
            "tobramycin",
            "trimethoprim/sulfamethoxazole"
        ],
        rnd_antibiotics: [
            "amikacin",
            "amoxicillin/clavulanate",
            "ampicillin/sulbactam",
            "cefazolin",
            "cefepime",
            "ceftazidime",
            "cefuroxime",
            "doxycycline",
            "ertapenem",
            "meropenem",
            "minocycline",
            "piperacillin/tazobactam"
        ]
    },
    {
        id: 8,
        organism: "Proteus mirabilis",
        qualified_antibiotics: [
            "ampicillin",
            "ciprofloxacin",
            "levofloxacin"
        ],
        rnd_antibiotics: [
            "ampicillin/sulbactam",
            "gentamicin",
            "tobramycin",
            "trimethoprim/sulfamethoxazole"
        ]
    },
    {
        id: 9,
        organism: "Pseudomonas aeruginosa group",
        qualified_antibiotics: [],
        rnd_antibiotics: [
            "amikacin",
            "aztreonam",
            "cefepime",
            "ceftazidime",
            "ciprofloxacin",
            "gentamicin",
            "imipenem",
            "levofloxacin",
            "meropenem",
            "piperacillin/tazobactam",
            "ticarcillin/clavulanate",
            "tobramycin"
        ]
    },
    {
        id: 10,
        organism: "Staphylococcus aureus",
        qualified_antibiotics: [
            "ciprofloxacin",
            "clindamycin",
            "doxycycline",
            "erythromycin",
            "gentamicin",
            "levofloxacin",
            "moxifloxacin",
            "oxacillin",
            "tetracycline"
        ],
        rnd_antibiotics: [
            "penicillin",
            "rifampicin",
            "trimethoprim/sulfamethoxazole"
        ]
    },
    {
        id: 11,
        organism: "Staphylococcus epidermidis",
        qualified_antibiotics: [
            "ciprofloxacin",
            "clindamycin",
            "doxycycline",
            "erythromycin",
            "gentamicin",
            "levofloxacin",
            "moxifloxacin",
            "oxacillin",
            "tetracycline"
        ],
        rnd_antibiotics: [
            "rifampicin",
            "trimethoprim/sulfamethoxazole"
        ]
    },
    {
        id: 12,
        organism: "Staphylococcus hominis",
        qualified_antibiotics: [
            "ciprofloxacin",
            "erythromycin",
            "levofloxacin",
            "moxifloxacin"
        ],
        rnd_antibiotics: [
            "clindamycin",
            "tetracycline"
        ]
    },
    {
        id: 13,
        organism: "Staphylococcus lugdunensis",
        qualified_antibiotics: [
            "erythromycin"
        ],
        rnd_antibiotics: [
            "clindamycin",
            "tetracycline"
        ]
    },
    {
        id: 14,
        organism: "Streptococcus agalactiae",
        qualified_antibiotics: [
            "clindamycin",
            "erythromycin"
        ],
        rnd_antibiotics: [
            "tetracycline"
        ]
    },
    {
        id: 15,
        organism: "Streptococcus pneumoniae",
        qualified_antibiotics: [
            "cefuroxime",
            "clarithromycin",
            "clindamycin",
            "doxycycline",
            "erythromycin",
            "trimethoprim/sulfamethoxazole"
        ],
        rnd_antibiotics: [
            "ceftriaxone",
            "meropenem",
            "penicillin",
            "tetracycline"
        ]
    }
];


const KgASTv0_7_6_0PanelReference = [
    {
        id: 1,
        "organism": "Pseudomonas aeruginosa group",
        "qualified_antibiotics": [],
        "rnd_antibiotics": [
            "amikacin",
            "ciprofloxacin",
            "tobramycin",
        ]
    },
    {
        id: 3,
        "organism": "Morganella morganii",
        "qualified_antibiotics": [],
        "rnd_antibiotics": [
            "ciprofloxacin",
            "levofloxacin",
            "tetracycline",
            "trimethoprim/sulfamethoxazole",
        ]
    },
    {
        id: 4,
        "organism": "Citrobacter freundii complex",
        "qualified_antibiotics": [],
        "rnd_antibiotics": [
            "tetracycline"
        ]
    },
    {
        id: 5,
        "organism": "Enterobacter cloacae complex",
        "qualified_antibiotics": [],
        "rnd_antibiotics": [
            "cefuroxime",
            "ciprofloxacin",
            "gentamicin",
            "levofloxacin",
            "trimethoprim/sulfamethoxazole",
        ]
    },
    {
        id: 6,
        "organism": "Acinetobacter calcoaceticus/baumannii complex",
        "qualified_antibiotics": [
            "amikacin",
            "ciprofloxacin",
            "gentamicin",
            "imipenem",
            "meropenem"
        ],
        "rnd_antibiotics": [
            "cefepime",
            "ceftazidime",
            "colistin",
            "levofloxacin",
            "piperacillin/tazobactam",
            "tetracycline",
            "tobramycin",
            "trimethoprim/sulfamethoxazole",
        ]
    },
    {
        id: 7,
        "organism": "Klebsiella pneumoniae",
        "qualified_antibiotics": [
            "levofloxacin",
            "imipenem",
            "gentamicin",
            "ertapenem",
            "ciprofloxacin",
            "ceftriaxone",
            "aztreonam",
            "tobramycin"
        ],
        "rnd_antibiotics": [
            "amikacin",
            "amoxicillin/clavulanate",
            "ampicillin/sulbactam",
            "cefepime",
            "cefotaxime",
            "ceftazidime",
            "cefuroxime",
            "doripenem",
            "meropenem",
            "moxifloxacin",
            "nalidixic acid",
            "tetracycline",
            "ticarcillin/clavulanate",
            "trimethoprim/sulfamethoxazole",
        ]
    },
    {
        id: 8,
        "organism": "Escherichia coli",
        "qualified_antibiotics": [
            "trimethoprim/sulfamethoxazole",
            "tetracycline",
            "levofloxacin",
            "gentamicin",
            "ciprofloxacin",
            "cefuroxime",
            "ceftriaxone",
            "ceftazidime",
            "cefepime",
            "cefazolin",
            "aztreonam",
            "ampicillin",
        ],
        "rnd_antibiotics": [
            "amoxicillin",
            "ampicillin/sulbactam",
            "cefotaxime",
            "ertapenem",
            "imipenem",
            "tobramycin",
        ]
    },
    {
        id: 9,
        "organism": "Enterococcus faecium",
        "qualified_antibiotics": [
            "vancomycin",
            "tetracycline",
            "levofloxacin"
        ],
        "rnd_antibiotics": [
            "ampicillin",
            "ciprofloxacin",
            "daptomycin",
            "doxycycline",
        ]
    },
    {
        id: 10,
        "organism": "Proteus mirabilis",
        "qualified_antibiotics": [
            "ciprofloxacin",
            "levofloxacin"
        ],
        "rnd_antibiotics": [
            "ampicillin",
            "ampicillin/sulbactam",
            "gentamicin",
            "trimethoprim/sulfamethoxazole",
        ]
    },
    {
        id: 11,
        "organism": "Staphylococcus aureus",
        "qualified_antibiotics": [
            "ciprofloxacin",
            "tetracycline",
            "oxacillin",
            "moxifloxacin",
            "levofloxacin",
            "gentamicin",
            "erythromycin",
            "clindamycin",
        ],
        "rnd_antibiotics": [
            "cefoxitin",
            "doxycycline",
            "methicillin",
            "rifampicin",
            "trimethoprim/sulfamethoxazole",
        ]
    },
    {
        id: 12,
        "organism": "Streptococcus pneumoniae",
        "qualified_antibiotics": [
            "erythromycin",
            "clarithromycin",
            "trimethoprim/sulfamethoxazole"
        ],
        "rnd_antibiotics": [
            "cefuroxime",
            "clindamycin",
            "doxycycline",
            "meropenem",
            "tetracycline",
        ]
    },
    {
        id: 13,
        "organism": "Streptococcus agalactiae",
        "qualified_antibiotics": [
            "erythromycin",
            "clindamycin",
        ],
        "rnd_antibiotics": []
    },
    {
        id: 14,
        "organism": "Staphylococcus hominis",
        "qualified_antibiotics": [
            "ciprofloxacin"
        ],
        "rnd_antibiotics": [
            "erythromycin",
            "moxifloxacin",
            "tetracycline",
        ]
    },
    {
        id: 15,
        "organism": "Staphylococcus epidermidis",
        "qualified_antibiotics": [
            "tetracycline",
            "oxacillin",
            "moxifloxacin",
            "levofloxacin",
            "gentamicin",
            "erythromycin",
            "doxycycline",
            "clindamycin",
            "ciprofloxacin",
        ],
        "rnd_antibiotics": [
            "rifampicin",
            "trimethoprim/sulfamethoxazole",
        ]
    },
    {
        id: 16,
        "organism": "Enterococcus faecalis",
        "qualified_antibiotics": [
            "tetracycline",
            "erythromycin",
            "gentamicin high level",
            "levofloxacin",
            "ciprofloxacin"
        ],
        "rnd_antibiotics": [
            "minocycline",
            "vancomycin",
        ]
    },
    {
        id: 17,
        "organism": "Staphylococcus lugdunensis",
        "qualified_antibiotics": [],
        "rnd_antibiotics": [
            "erythromycin",
        ]
    }
];

const KgASTv0_7_7_0PanelReference = [
    {
        id: 1,
        organism: "Acinetobacter calcoaceticus/baumannii complex",
        qualified_antibiotics: [
            "amikacin",
            "ampicillin/sulbactam",
            "ciprofloxacin",
            "gentamicin",
            "imipenem",
            "levofloxacin",
            "meropenem",
            "minocycline",
            "trimethoprim/sulfamethoxazole"
        ],
        rnd_antibiotics: [
            "cefepime",
            "ceftazidime",
            "colistin",
            "doripenem",
            "piperacillin/tazobactam",
            "tetracycline"
        ]
    },
    {
        id: 2,
        organism: "Citrobacter freundii complex",
        qualified_antibiotics: [
            "trimethoprim/sulfamethoxazole"
        ],
        rnd_antibiotics: [
            "aztreonam",
            "ceftriaxone",
            "ciprofloxacin",
            "levofloxacin",
            "piperacillin/tazobactam",
            "tetracycline"
        ]
    },
    {
        id: 3,
        organism: "Enterobacter cloacae complex",
        qualified_antibiotics: [
            "amikacin",
            "ciprofloxacin",
            "gentamicin",
            "levofloxacin",
            "trimethoprim/sulfamethoxazole"
        ],
        rnd_antibiotics: [
            "aztreonam",
            "cefuroxime",
            "colistin",
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "minocycline",
            "tobramycin"
        ]
    },
    {
        id: 4,
        organism: "Enterococcus faecalis",
        qualified_antibiotics: [
            "ciprofloxacin",
            "doxycycline",
            "levofloxacin",
            "tetracycline",
            "vancomycin"
        ],
        rnd_antibiotics: [
            "erythromycin",
            "gentamicin high level",
            "linezolid",
            "minocycline",
            "streptomycin high level"
        ]
    },
    {
        id: 5,
        organism: "Enterococcus faecium",
        qualified_antibiotics: [
            "ampicillin",
            "ciprofloxacin",
            "levofloxacin",
            "tetracycline",
            "vancomycin"
        ],
        rnd_antibiotics: [
            "daptomycin",
            "doxycycline",
            "gentamicin high level",
            "nitrofurantoin",
            "teicoplanin"
        ]
    },
    {
        id: 6,
        organism: "Escherichia coli",
        qualified_antibiotics: [
            "amikacin",
            "ampicillin",
            "aztreonam",
            "cefazolin",
            "ceftriaxone",
            "cefuroxime",
            "ciprofloxacin",
            "ertapenem",
            "gentamicin",
            "imipenem",
            "levofloxacin",
            "tetracycline",
            "trimethoprim/sulfamethoxazole"
        ],
        rnd_antibiotics: [
            "amoxicillin/clavulanate",
            "ampicillin/sulbactam",
            "cefepime",
            "ceftazidime",
            "meropenem",
            "piperacillin/tazobactam",
            "tobramycin"
        ]
    },
    {
        id: 7,
        organism: "Klebsiella aerogenes",
        qualified_antibiotics: [],
        rnd_antibiotics: [
            "aztreonam",
            "cefepime",
            "ceftriaxone",
            "ertapenem",
            "imipenem",
            "levofloxacin",
            "nitrofurantoin",
            "piperacillin/tazobactam"
        ]
    },
    {
        id: 8,
        organism: "Klebsiella michiganensis",
        qualified_antibiotics: [],
        rnd_antibiotics: [
            "ampicillin/sulbactam",
            "nitrofurantoin",
            "piperacillin/tazobactam",
            "trimethoprim/sulfamethoxazole"
        ]
    },
    {
        id: 9,
        organism: "Klebsiella oxytoca",
        qualified_antibiotics: [],
        rnd_antibiotics: [
            "ampicillin/sulbactam",
            "piperacillin/tazobactam"
        ]
    },
    {
        id: 10,
        organism: "Klebsiella pneumoniae",
        qualified_antibiotics: [
            "amikacin",
            "ampicillin/sulbactam",
            "ciprofloxacin",
            "ertapenem",
            "gentamicin",
            "imipenem",
            "levofloxacin",
            "tetracycline",
            "tobramycin",
            "trimethoprim/sulfamethoxazole"
        ],
        rnd_antibiotics: [
            "amoxicillin/clavulanate",
            "cefazolin",
            "cefepime",
            "ceftazidime",
            "ceftazidime/avibactam",
            "ceftolozane/tazobactam",
            "colistin",
            "doxycycline",
            "meropenem",
            "minocycline",
            "piperacillin/tazobactam",
            "polymyxin B1",
            "tigecycline"
        ]
    },
    {
        id: 11,
        organism: "Klebsiella quasipneumoniae",
        qualified_antibiotics: [],
        rnd_antibiotics: [
            "nitrofurantoin",
            "piperacillin/tazobactam",
            "trimethoprim/sulfamethoxazole"
        ]
    },
    {
        id: 12,
        organism: "Klebsiella variicola",
        qualified_antibiotics: [],
        rnd_antibiotics: [
            "ciprofloxacin",
            "nitrofurantoin"
        ]
    },
    {
        id: 13,
        organism: "Morganella morganii",
        qualified_antibiotics: [
            "ciprofloxacin"
        ],
        rnd_antibiotics: [
            "imipenem",
            "levofloxacin",
            "tetracycline",
            "trimethoprim/sulfamethoxazole"
        ]
    },
    {
        id: 14,
        organism: "Proteus mirabilis",
        qualified_antibiotics: [
            "ampicillin",
            "ciprofloxacin",
            "levofloxacin"
        ],
        rnd_antibiotics: [
            "ampicillin/sulbactam",
            "ertapenem",
            "gentamicin",
            "imipenem",
            "tobramycin",
            "trimethoprim/sulfamethoxazole"
        ]
    },
    {
        id: 15,
        organism: "Pseudomonas aeruginosa group",
        qualified_antibiotics: [],
        rnd_antibiotics: [
            "amikacin",
            "aztreonam",
            "cefepime",
            "ceftazidime",
            "ceftazidime/avibactam",
            "ceftolozane/tazobactam",
            "ciprofloxacin",
            "doripenem",
            "gentamicin",
            "imipenem",
            "levofloxacin",
            "meropenem",
            "piperacillin/tazobactam",
            "ticarcillin/clavulanate",
            "tobramycin"
        ]
    },
    {
        id: 16,
        organism: "Serratia marcescens",
        qualified_antibiotics: [],
        rnd_antibiotics: [
            "ciprofloxacin",
            "levofloxacin",
            "tetracycline"
        ]
    },
    {
        id: 17,
        organism: "Staphylococcus aureus",
        qualified_antibiotics: [
            "ciprofloxacin",
            "doxycycline",
            "erythromycin",
            "levofloxacin",
            "moxifloxacin",
            "oxacillin",
            "rifampicin",
            "tetracycline",
            "trimethoprim/sulfamethoxazole"
        ],
        rnd_antibiotics: [
            "clindamycin",
            "daptomycin",
            "gentamicin",
            "penicillin",
            "vancomycin"
        ]
    },
    {
        id: 18,
        organism: "Staphylococcus epidermidis",
        qualified_antibiotics: [
            "ciprofloxacin",
            "doxycycline",
            "erythromycin",
            "gentamicin",
            "levofloxacin",
            "moxifloxacin",
            "oxacillin",
            "tetracycline"
        ],
        rnd_antibiotics: [
            "clindamycin",
            "rifampicin",
            "trimethoprim/sulfamethoxazole"
        ]
    },
    {
        id: 19,
        organism: "Staphylococcus hominis",
        qualified_antibiotics: [],
        rnd_antibiotics: [
            "ciprofloxacin",
            "clindamycin",
            "erythromycin",
            "oxacillin",
            "tetracycline"
        ]
    },
    {
        id: 20,
        organism: "Staphylococcus lugdunensis",
        qualified_antibiotics: [],
        rnd_antibiotics: [
            "clindamycin",
            "erythromycin"
        ]
    },
    {
        id: 21,
        organism: "Streptococcus agalactiae",
        qualified_antibiotics: [
            "clindamycin",
            "erythromycin"
        ],
        rnd_antibiotics: [
            "tetracycline"
        ]
    },
    {
        id: 22,
        organism: "Streptococcus pneumoniae",
        qualified_antibiotics: [
            "cefuroxime",
            "clarithromycin",
            "clindamycin",
            "doxycycline",
            "erythromycin",
            "tetracycline",
            "trimethoprim/sulfamethoxazole"
        ],
        rnd_antibiotics: [
            "amoxicillin/clavulanate",
            "azithromycin",
            "ceftriaxone",
            "chloramphenicol",
            "ciprofloxacin",
            "meropenem",
            "penicillin"
        ]
    }
];

const modelPanelReference = {
    "v0.7.5.1": KgASTv0_7_5_1PanelReference,
    "v0.7.6.0": KgASTv0_7_6_0PanelReference,
    "v0.7.7.0": KgASTv0_7_7_0PanelReference
}

export default modelPanelReference;